<template>
  <div class="LoginMainDiv">
    <el-container style="height: 100vh; padding: 0">
      <el-header style="padding: 0">
        <top-header></top-header>
      </el-header>
      <el-main style="padding: 0">
        <div class="fja" style="height: 80%">
          <el-card class="card-box_o">
            <el-row>
              <el-col :span="12">
                <el-row>
                  <el-col class="titleSpanCol1" :span="24">
                    <span class="titleSpan">Shanghai BARK</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <span class="titleSpan">ERP Platform</span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="12">
                <el-form :model="loginForm" ref="from" class="vd_from">
                  <el-row>
                    <el-col :span="24" class="lockIcon">
                      <i class="el-icon-lock"></i>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-form-item prop="custCode">
                      <el-input type="text" v-model="loginForm.custCode" maxlength="16" placeholder="Username" size="medium" class="vd_input" clearable>
                        <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
                      </el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-form-item prop="custPass">
                      <el-input
                        @keyup.enter.native="chooseLogin"
                        type="password"
                        v-model="loginForm.custPass"
                        minlength="6"
                        maxlength="16"
                        placeholder="Password"
                        class="vd_input"
                        show-password
                        clearable
                      >
                        <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                      </el-input>
                    </el-form-item>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item class="vd_login_button">
                        <el-button type="primary" @click="chooseLogin" class="signInText" size="medium">
                          <span>Sign&nbsp;In</span>
                        </el-button>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
            </el-row>
          </el-card>
        </div>
      </el-main>
      <el-footer style="padding: 0">
        <div class="fja">
          <div>
            <el-link href="https://beian.miit.gov.cn/#/Integrated/index" target="“_blank”" :underline="false" class="recordHref"
              >沪ICP备16001718号-5&nbsp;&nbsp;</el-link
            >
          </div>
          <div>
            <span>All Rights Reserved by Fantastic Pet 2022</span>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { get, post } from '@/api/request';
import { userAPI } from '@/api/modules/user';
import topHeader from '@/components/TopHeader.vue';
import { systemAPI } from '@api/modules/version';
import jwt_decode from 'jwt-decode';
export default {
  name: 'login',
  components: {
    topHeader
  },
  data() {
    return {
      loginForm: {
        custCode: '',
        custPass: ''
      }
    };
  },
  methods: {
    getVersion() {
      get(systemAPI.getVersion, '').then(res => {
        if (res.data.code === 0) {
          if (sessionStorage.getItem('loaded') !== res.data.data.version + '') {
            sessionStorage.setItem('loaded', res.data.data.version + '');
            window.location.reload();
          }
        } else {
          this.$message({ message: res.data.msg, type: 'error' });
        }
      });
    },
    chooseLogin() {
      let loginForm = {};
      loginForm = JSON.parse(JSON.stringify(this.loginForm));
      if (!loginForm.custCode && !loginForm.custPass) {
        this.$message.warning('Please enter your user name and password.');
        return;
      }
      post(userAPI.userLogin, loginForm)
        .then(res => {
          // 登录成功
          if (res.data.code === 0) {
            let { sub } = jwt_decode(res.data.data);
            this.$store.commit('setUserName', sub);
            localStorage.setItem('token', res.data.data);
            localStorage.setItem('userName', sub);
            // this.$message({ message: 'Welcome to Fantastic Platform!', type: 'success' });
            this.getVersion();
            this.$router.push('/home');
          } else {
            //登陆不成功
            this.$message({ message: res.data.msg, type: 'error' });
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.fja {
  display: flex;
  justify-content: center;
  align-items: center;
}
.LoginMainDiv {
  width: 100%;
  height: 100%;
}

.mainRow {
}

// 包裹了表单和fcp标题
.card-box {
  width: 1156px;
  box-sizing: border-box;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-box_o {
  width: 1156px;
  padding: 6% 3% 8% 3%;
  box-sizing: border-box;
  background-image: url(../assets/image/loginBack.png);
  background-repeat: no-repeat;
  background-position: 32% 95%;
  background-size: 30%;
}
// FCP标题第一行
.titleSpanCol1 {
  margin-top: 50px;
  margin-bottom: 30px;
}

// FCP标题
.titleSpan {
  font-size: 50px;
  font-weight: bold;
}

// 锁图标
.lockIcon {
  font-size: 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

// 表单本体
.vd_from {
  margin-left: 60px;
}

/* 登录按钮 */
.vd_login_button {
  width: 100%;
}

/* 登录按钮字体 */
.signInText {
  width: 100%;
}

/* 输入框前的图标 */
i {
  color: black;
}

.foots {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -70px;
  left: 0;
  box-sizing: border-box;
  color: rgba($color: grey, $alpha: 0.8);
  font-size: 14px;
}

.recordHref {
  font-size: 13px;
  color: rgba($color: grey, $alpha: 0.8);
}
</style>
